// @ts-ignore
import { blobFileExtension } from '@/util'
import {createFFmpeg, fetchFile} from '@ffmpeg/ffmpeg'

class Transcoder {
    ffmpeg = createFFmpeg()

    async convertToMp4(blob: Blob): Promise<Blob> {
        return this.run(blob, ['-c:v', 'libx264'])
    }
    async repackageToMp4(blob: Blob): Promise<Blob> {
        return this.run(blob, ['-vcodec', 'copy'])
    }

    async run(blob: Blob, args: string[]) {
        if (!this.ffmpeg.isLoaded()) {
            await this.ffmpeg.load()
        }
        const inputFilename = 'input.'+blobFileExtension(blob)
        this.ffmpeg.FS('writeFile', inputFilename, await fetchFile(blob));
        await this.ffmpeg.run('-i', inputFilename, ...args, 'output.mp4');
        const data = this.ffmpeg.FS('readFile', 'output.mp4');
        return new Blob([data.buffer], {type: 'video/mp4'});
    }
}

const transcoder = new Transcoder()
export default transcoder;
